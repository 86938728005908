/*App.js*/

import React, { useState, useEffect, useMemo } from 'react';
import { Image } from 'react-bootstrap';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { setCookie, getCookie } from './utils'; // Import the functions

import { GoogleOAuthProvider } from '@react-oauth/google';

import connectlogo from "assets/img/btn_strava_connectwith_orange.png";

import { Chart } from "react-google-charts";

import HtmlContent from "components/Custom/htmlContent";
import content from 'content.json'; // Importing the JSON file
 
// todo: get brandname from env
const brandname = content.brandname;
const vApplication = content.application;
const vConnect_app_info = content.features.connect_app_info;

const login_api_url = process.env.REACT_APP_LOGIN_API_URL;
const resource_api_url = process.env.REACT_APP_RESOURCE_API_URL;
const env = process.env.NODE_ENV;
console.log('login_api_url', login_api_url); 
console.log('resource_api_url', resource_api_url);
console.log('brandname', brandname);
console.log('env', env);  


function App() {
    const [showGoogleLogin, setShowGoogleLogin] = useState(true);
    const [user, setUser] = useState(null);
    const [strava_data, setStrava_data] = useState(null);
    const [listItems, setListItems] = useState(null);
    const [responseData, setResponseData] = useState({});
    const [loadingstatus, setLoadingstatus] = useState({});
    const [menu, setMenu] = useState('Lets get Started, checking if you are logged in');
    const [status, setStatus] = useState(null);
    const [showConnectButton, setShowConnectButton] = useState(false);
    const [chartKey, setChartKey] = useState(0);
    const [showChart, setShowChart] = useState(true);


    
    const dataOld = [
        ["Name", "PPM"],
        ["Start", 0],
    ];
    
    const dataNew = [
        ["Name", "PPM"],
        ["Start", 0],
        
    ];
    
    const diffdatai = {
        old: dataOld,
        new: dataNew,
    };
    
    const options = {
        legend: {
            position: "top", 
            textStyle: { color: 'white' }  // Legend text color (white)
        },
        hAxis: {
            viewWindow: {
                max: 900,
                min: 700
            },
            textStyle: { color: 'white' }  // Horizontal axis label color (white)
        },
        vAxis: {
            textStyle: { color: 'white' }  // Vertical axis label color (white)
        },
        colors: ['#64b4c4', '#64b4c4'],  // Custom colors for bars
        series: {
            0: { color: '#64b4c4' },  // First series
            1: { color: '#64b4c4' },  // Second series
        },
        backgroundColor: '#000000',  // Background color (black)
        chartArea: {
            backgroundColor: '#000000',  // Chart area background color (black)
        },
        titleTextStyle: {
            color: 'white'  // Title text color (if you have a title)
        },
        annotations: {
            alwaysOutside: true,  // Place annotations outside the bars
            textStyle: {
                fontSize: 12,
                color: 'white',  // Annotation text color (white for visibility)
                auraColor: 'none'  // Disable the background behind the text
            }
        }
    };
  
    const [old_bar_data, setOld_bar_data] = useState(dataOld);
    const [new_bar_data, setNew_bar_data] = useState(dataNew);
    const [diffdata, setDiff_data] = useState(diffdatai);

    function onClickHandler(){
        
        setMenu(<div className="content">After logging into google, please wait a few seconds for this page to load <br /><br/>{googleLoginButton}</div>);
      }

    const googleLoginButton = useMemo(() => {

        return (
            <GoogleLogin
                shape='pill'
                click_listener={() => onClickHandler()}
                onSuccess={credentialResponse => {
                    console.log('login url:::', login_api_url);
                    axios.post(`${login_api_url}/api/Login`, {
                        test: 'test'
                    }, {
                        headers: {
                            Authorization: credentialResponse.credential,
                        }
                    })
                        .then((res) => {
                            setCookie(`${brandname}_token`, res.data.token, { path: '/', expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365) });
                            setShowGoogleLogin(false);
                            checkToken();
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }}
                onError={() => {
                    console.log('Login Failed');
                }}
                
            />
        );
    }, []);

    const ConnectButton = useMemo(() => {
      
        return <button onClick={() => {
            console.log('131: Spotify authorisatie code halen');
            const clientId = 'bfdb5a89ac364da89f876b3d1fab1d9a';
            const redirectUri = `${resource_api_url}/api/Store_Tokens`;
            const scope = 'user-library-read';
            const state = getCookie(`${brandname}_token`);
            const response_type = 'code';
            const authUrl = `https://accounts.spotify.com/authorize?state=${state}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${response_type}`;
            window.open(authUrl, '_self');
        }}>Open {vApplication} Login</button>
    
  }, []);

    function checkToken() {
        const cookieValue = getCookie(`${brandname}_token`);
        if (cookieValue) {
            axios.get(`${login_api_url}/api/CheckToken`, {
                headers: {
                    Authorization: cookieValue,
                }
            })
                .then(response => {
                    if (response.data.userid) {
                        setUser(response.data.userid);
                        setStatus(response.status);
                    }
                })
                .catch(error => {
                    setStatus(error.response.status);
                });
        } else {
            setStatus(401);
            setShowGoogleLogin(true);
        }
    }
    function fetchActivities(processed = 0) {
        axios.get(`${resource_api_url}/api/Get_Activities?offset=${processed}`, {
            headers: {
                Authorization: getCookie(`${brandname}_token`),
            }
        })
        .then((res) => {
            if (res.status === 200) {
                // Get the body of the response, showing the percentage of items loaded
                const processedItems = res.data.processed;
                const totalItems = res.data.total;
    
                if (processedItems < totalItems) {
                    // Sleep half a second
                    // setTimeout(() => {
                        fetchActivities(processedItems);
                        setMenu(`Processing items: ${processedItems} of ${totalItems}`);
                    // }, 500);
                } else {
                    matching();
                }
            } else {
                setStatus(res.status);
            }
        })
        .catch((err) => {
            setStatus(err.response ? err.response.status : 'Error');
        });
    }
    
    function clearData() {
        axios.get(`${resource_api_url}/api/Clear_Data`, {
            headers: {
                Authorization: getCookie(`${brandname}_token`),
            }
        })
        .then((res) => {
            if (res.status === 200) {
                // Get the body of the response, showing the percentage of items loaded
                setMenu(`All items cleared`);
            } else {
                setStatus(res.status);
            }
        })
        .catch((err) => {
            setStatus(err.response ? err.response.status : 'Error');
        });
    }
    
    useEffect(() => {
        switch (status) {
            case 401:
                setMenu(<div className="content">Welcome, this is a first-time visit. Please log in using Google <br />{googleLoginButton}</div>);
                break;
            case 202:
                setMenu(<div className="content">Logged in / Authenticated, checking if we have your {vApplication} tokens.</div>);
                axios.get(`${resource_api_url}/api/User`, {
                    headers: {
                        Authorization: getCookie(`${brandname}_token`),
                    }
                })
                    .then((res) => {
                        setStatus(res.status);
                    })
                    .catch((err) => {
                        setStatus(err.response.status);
                    });
                break;
            case 204:
                setMenu(<div className="content">We have your {vApplication} tokens to log in! Matching your tracks with Events, please wait a minute..</div>);
                // Initial call to fetchActivities
                fetchActivities();

                // axios.get(`${resource_api_url}/api/Get_Activities?offset=0`, {
                //     headers: {
                //         Authorization: getCookie(`${brandname}_token`),
                //     }
                // })
                //     .then((res) => {
                //         if (res.status === 200) {
                            
                //             setStrava_data(res.data.items);
                            
                //             console.log('res.items', res.data.items);
                //             console.log('stravadata', strava_data);
                //             // setStrava_data(res.data);
                //         } else {
                //             setStatus(res.status);
                //         }
                //     })
                //     .catch((err) => {
                //         setStatus(err.response.status);
                //     });
                break;
            case 404:
                setMenu(<div className="content"> <HtmlContent html={vConnect_app_info} />  {ConnectButton}</div>);
                // setMenu(<HtmlContent html={vConnect_app_info} />);
                break;
            case 440:
                setMenu(<div className="content">Session Expired, please log in again <br /><br/>{googleLoginButton}</div>);
                setShowGoogleLogin(true);
                break;
            default:
                console.log('Unknown status');
                checkToken();
                break;
        }
    }, [status, googleLoginButton]);

    const updateStatus = (entryId, status) => {
        return new Promise((resolve) => {
            setLoadingstatus(prevStatus => {
                const newStatus = { ...prevStatus, [entryId]: status };
                resolve(newStatus);
                return newStatus;
            });
        });
    };

    const handleButtonClick = (entry, module=null, type) => {
        // First update the status
        if (type === 'station') {
            setLoadingstatus(prevStatus => ({
                ...prevStatus,
                [entry._id]: 'Getting data...'
            }));
        } else {
            setLoadingstatus(prevStatus => ({
                ...prevStatus,
                [module._id]: 'Getting data...'
            }));
        }
    
        // Ensure the UI updates before making the API call
        setTimeout(() => {
            axios.post(`${resource_api_url}/api/Get_Activity`, {
                station_id: entry._id,
                module_id: module ? module._id : null,
                type: type
            }, {
                headers: {
                    Authorization: getCookie(`${brandname}_token`),
                }
            })
                .then((res) => {
                    
                    
                    const newDataArray = (type == 'station') ?  [entry.station_name, res.data.today] : [module.module_name, res.data.today];
                    const oldDataArray = (type == 'station') ?  [entry.station_name, res.data.yesterday] : [module.module_name, res.data.yesterday];
                    ;  // Create new data row (ID and data)
    
                    // Update bar_data by appending the new row
                    const updatedOldBarData = [...old_bar_data, oldDataArray];
                    const updatedNewBarData = [...new_bar_data, newDataArray];
    
                    setOld_bar_data(updatedOldBarData);
                    setNew_bar_data(updatedNewBarData);
    
                    // Now update diffdata with the updated bar data
                    setDiff_data({
                        old: updatedOldBarData,
                        new: updatedNewBarData,
                    });
    
                    if (type === 'station') {
                        setLoadingstatus(prevStatus => ({
                            ...prevStatus,
                            [entry._id]: 'Here is your data'
                        }));
                        setResponseData(prevData => ({
                            ...prevData,
                            [entry._id]: res.data
                        }));
                    } else {
                        setLoadingstatus(prevStatus => ({
                            ...prevStatus,
                            [module._id]: 'Here is your data'
                        }));
                        setResponseData(prevData => ({
                            ...prevData,
                            [module._id]: res.data
                        }));
                    }
                })
                .catch((err) => {
                    if (err.response.status === 401) {
                        setShowGoogleLogin(true);
                    } else if (err.response.status === 404) {
                        setShowConnectButton(true);
                    }
                });
        }, 100); // Adding a small delay to ensure UI update
    };

    

    const [expandedEvents, setExpandedEvents] = useState({});
    const [expandedArtists, setExpandedArtists] = useState({});

    useEffect(() => {
    const toggleEventExpand = (eventName) => {
        console.log('toggleEventExpand', eventName);
        console.log('expandedEvents', expandedEvents);
        console.log('expandedEvents[eventName]', expandedEvents[eventName]);
        setExpandedEvents((prevState) => ({
            ...prevState,
            [eventName]: !prevState[eventName],
        }));
        console.log('toggleEventExpand', eventName);
        console.log('expandedEvents', expandedEvents);
        console.log('expandedEvents[eventName]', expandedEvents[eventName]);
    };

    const toggleArtistExpand = (eventName, artistName) => {
        console.log('toggleArtistExpand', eventName, artistName);
        setExpandedArtists((prevState) => ({
            ...prevState,
            [eventName]: {
                ...prevState[eventName],
                [artistName]: !prevState[eventName]?.[artistName],
            },
        }));
    };
        if (strava_data && typeof strava_data === 'object') {
            const modifiedStravaData = { ...strava_data };

Object.keys(modifiedStravaData).forEach((eventName) => {
    const event = modifiedStravaData[eventName];
    const artistCount = Object.keys(event.artists).length;
    
    const trackCount = Object.keys(event.artists).reduce(
        (totalTracks, artistName) => totalTracks + Object.keys(event.artists[artistName].tracks).length,
        0
    );

    // Add new keys at the event level
    event.artistCount = artistCount;
    event.trackCount = trackCount;
});
// Step 3: Convert object to array and sort by trackCount
const sortedEventsArray = Object.entries(modifiedStravaData)
    .sort(([, eventA], [, eventB]) => eventB.trackCount - eventA.trackCount);

// Step 4: Convert sorted array back to object
const sortedStravaData = Object.fromEntries(sortedEventsArray);

console.log(sortedStravaData);
            const listItems = Object.keys(sortedStravaData).map((eventName) => (
                <div>                
                        <div key={eventName}>
                        Artistcount: {modifiedStravaData[eventName].artistCount} Trackcount: {modifiedStravaData[eventName].trackCount}
                            <h2 onClick={() => toggleEventExpand(eventName)}>
                                {eventName} {expandedEvents[eventName] ? '-' : '+'} <br />
                                </h2>
                                
                            
                            {expandedEvents[eventName] && (
                           
                           <div>
                                    {Object.keys(strava_data[eventName].artists).map((artistName) => (
                                        <div key={artistName}>
                                            <h3 onClick={() => toggleArtistExpand(eventName, artistName)}
                                                 style={{ marginLeft: '40px' }} >
                                                {artistName} {expandedArtists[eventName]?.[artistName] ? '-' : '+'} 
                                            </h3>
                                            {expandedArtists[eventName]?.[artistName] && (
                                                <ul>
                                                    {Object.keys(strava_data[eventName].artists[artistName].tracks).map(
                                                        (trackName) => (
                                                            <li key={trackName} style={{ marginLeft: '80px' }}>
                                                                <a
                                                                    href={
                                                                        strava_data[eventName].artists[artistName].tracks[
                                                                            trackName
                                                                        ]
                                                                    }
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    {trackName}
                                                                </a>
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    
            </div>
            ));
            setListItems(listItems);
        } else {
            console.error("strava_data is not an array, it's an object.", strava_data);
        }
    }, [strava_data, responseData, loadingstatus, expandedEvents, expandedArtists]);
    

    const cleardata = () => {
        // Step 1: Hide the chart and set data states to null/empty to fully clear out
        setShowChart(false);  // Temporarily hide the chart
        
        setDiff_data(null);    // Clear diffdata to reset chart
        setOld_bar_data([]);   // Clear old bar data
        setNew_bar_data([]);   // Clear new bar data
    
        // Step 2: Reinitialize the chart data after a small delay to ensure full reset
        setTimeout(() => {
            const dataOld = [
                ["Name", "PPM"],
                ["Start", 0],
            ];
    
            const dataNew = [
                ["Name", "PPM"],
                ["Start", 0],
            ];
    
            const diffdatai = {
                old: dataOld,
                new: dataNew,
            };
    
            // Step 3: Reset the state with fresh data
            setOld_bar_data(dataOld);
            setNew_bar_data(dataNew);
            setDiff_data(diffdatai);  // Reset the chart data
    
            // Step 4: Show the chart again by setting showChart to true
            setShowChart(true);  // Reintroduce the chart
        }, 500); // Use a delay to ensure the chart component fully unmounts
    };

function matching() {
    axios.get(`${resource_api_url}/api/Matching`, {
        headers: {
            Authorization: getCookie(`${brandname}_token`),
        }
    })
    .then((res) => {
        if (res.status === 200) {
            console.log(res.data);
            setStrava_data(res.data);
            // setMenu(res.data);
        } else {
            setStatus(res.status);
        }
    });
}


    return (
      
        <div className="content">

    {/* <button onClick={() => clearData()}>ClearData</button>
    <button onClick={() => fetchActivities()}>fetch Tracks</button>
    <button onClick={() => matching()}>Matching</button>
    <button onClick={() => cleardata()}>Clear</button> */}


            {!showGoogleLogin && <p>{user}</p>}
            <br />
            {menu}
            {listItems && (
                <ul>
                    {listItems}
                </ul>
            )}
        </div>
    );
}

export default App;
