import React, { useEffect, useState } from "react";
// reactstrap components
import { Card, CardBody, Row, Col } from "reactstrap";

import HtmlContent from "components/Custom/htmlContent";
import content from 'content.json'; // Importing the JSON file

function Features() {
  const brandname = process.env.REACT_APP_BRAND_NAME;
  const [highleveldesc, setHighleveldesc] = useState('');




  console.log('brandname', brandname);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="10">
            <Card>
              <CardBody>
                <div className="typography-line">
                  <h1>{content.brandname}</h1>
        
        
      <HtmlContent html={content.features.intro} />
      <iframe width="619" height="1100" src="https://www.youtube.com/embed/P1bnF0AuJmM" title="Tracklist Event Matching" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      
                  <br />
          
                  
                  <HtmlContent html={content.features.features} />
                  <HtmlContent html={content.features.benefits} />

                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Features;
