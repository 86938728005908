/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import HtmlContent from "components/Custom/htmlContent";
import content from 'content.json'; // Importing the JSON file


// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";

function Privacy() {
  return (
    <>
      <div className="content">
        <Row>
          <Col md="10">
            <Card>
              
              <CardBody>
                <div className="typography-line">
              
<h1>
Privacy Policy for {content.brandname} 
</h1>
<p>
At {content.brandname}, we value and respect your privacy. This policy outlines the types of information we collect, how we use it, and the steps we take to protect your data, in accordance with relevant data protection laws such as the GDPR.
</p>
<br/>

<h2>
1. Information We Collect
</h2>
<p>
When you log into {content.brandname} using Google, we only collect your name as provided by the Google login functionality. This name is solely used to identify your {content.application} tokens, which allow us to retrieve your latest {content.entries}. We strictly adhere to {content.application}’s API guidelines and only request read permissions from your {content.application} account.
</p>
<br/>

<h2>
2. Use of {content.application} Data
</h2>
<p>
{content.brandname} will not modify your {content.entries}. Instead, we use tokens to securely pull your {content.entries}. The track data is stored in our system to prevent overloading the Spotify system by requesting all track information each time you visit this website. We ensure that our use of your data complies with {content.application}’s API agreement, which prohibits sharing, selling, or using the data for purposes such as training AI models.
</p>
<br/>

<h2>
3. Data Storage and Security
</h2>
<p>
The tokens we collect to access your {content.application} data are securely stored in our Azure backend infrastructure. We implement industry-standard security measures, including encryption and secure transmission (e.g., HTTPS), to protect your data. We are committed to respecting your privacy settings, as defined in your {content.application} account, and complying with all applicable data protection laws.
</p>
<br/>

<h2>
4. User Control
</h2>
<p>
You have full control over the data you share with {content.brandname}. At any time, you can revoke {content.brandname}'s access to your {content.application} account by adjusting your permissions in {content.application}. Additionally, if you choose to stop using our services, all tokens and access data will be deleted from our system upon request.
</p>
<br/>

<h2>
5. Changes to this Privacy Policy
</h2>
<p>
We reserve the right to modify this privacy policy at any time, in accordance with changes to our services or {content.application}’s API guidelines. Any updates will be posted here, and we encourage you to review this page regularly to stay informed about how we protect your data.
</p>
<br/>

                </div>
          
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Privacy;
