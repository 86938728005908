import React from "react";
import { NavLink } from "react-router-dom"; // Add NavLink here
import { Card, CardBody, Row, Col } from "reactstrap";
import content from 'content.json'; // Importing the JSON file
import HtmlContent from "components/Custom/htmlContent";


function Faq() {
  return (
    <>
      <div className="content">
        <Row>
          <Col md="10">
            <Card>
              <CardBody>
                <div className="typography-line">
                  <h1>Frequently Asked Questions (FAQ)</h1>

                  <h3>1. Are you affiliated with {content.application}?</h3>
                  <p>
                    No, {content.brandname} is not affiliated with {content.application} in any way. 
                    This website is an independent add-on tool that provides enhanced insights into your {content.application} {content.entries}. 
                    It is completely independent and is by no means officially connected to or endorsed by {content.application}.
                  </p>
                  <br />

                  <h3>2. Why do I need to log in with Google?</h3>
                  <p>
                    We use Google login because it provides a secure and reliable way to authenticate users. This also allows us to avoid managing passwords, password resets, and other account maintenance tasks directly, as Google manages these for us.
                  </p>
                  <br />

                  <h3>3. Why did you create {content.brandname}?</h3>
                  <p>
                    I created {content.brandname} to analyze my {content.entries}. This feature gives me a clear picture of {content.entries}, by showing the average values. I hope this app can help other users gain similar insights into their {content.application} {content.entries}!
                  </p>
                  <br />

                  <h3>4. Can I request new features?</h3>
                  <p>
                    Yes! I’m always open to feedback and new feature ideas. You can request features or report any issues by visiting our GitHub issues page at:
                    <HtmlContent html={content.issue_url} />
                  </p>
                  <br />

                  <h3>5. How does {content.brandname} handle my privacy?</h3>
                  <p>
                    We take your privacy very seriously. We only collect essential information to provide our services, and all data handling follows strict guidelines. For full details, please refer to our <NavLink to="/admin/Privacy">Privacy Policy</NavLink>.
                  </p>
                  <br />

                  <h3>6. Does {content.brandname} use cookies?</h3>
                  <p>
                    Yes, we use a functional cookie that stores your session login information for 3 hours. We do not use marketing or analytics cookies. For more information, please see our <NavLink to="/admin/Cookies">Cookie Policy</NavLink>.
                  </p>
                  <br />

                  {/* Add navigation links */}
                  <div>
                    <NavLink to="/" className="btn btn-primary">Home</NavLink>
                    <a href="mailto:steve.depeijper@gmail.com" className="btn btn-secondary">Contact Us</a>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Faq;
